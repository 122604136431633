var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "pt-1 pb-0 pl-2 pr-2" },
    [
      !_vm.hideLineNumber
        ? _c(
            "v-col",
            _vm._b(
              { staticClass: "pa-1 text-center" },
              "v-col",
              _vm.lineNumberProps,
              false
            ),
            [
              _vm.lineNumber
                ? _c("line-number", { attrs: { label: _vm.lineNumber } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.title
        ? _c("v-col", { staticClass: "pa-1" }, [
            _c(
              "div",
              { staticClass: "line-item--label" },
              [
                _vm.icon
                  ? _c(
                      "v-icon",
                      { attrs: { small: "", color: "yellow darken-1" } },
                      [_vm._v(_vm._s(_vm.icon) + " ")]
                    )
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.title) + " "),
                _vm.tooltip
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { color: "primary", dark: "" },
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(" mdi-help-circle ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2663964065
                        ),
                      },
                      [
                        _c("span", { staticStyle: { "white-space": "pre" } }, [
                          _vm._v(" " + _vm._s(_vm.tooltip) + " "),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("v-col", { staticClass: "pa-1" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }