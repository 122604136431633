var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "line-number rounded-circle d-inline-block" },
    [_vm._v(" " + _vm._s(_vm.label) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }