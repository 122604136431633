<template>
    <div
        class="line-number rounded-circle d-inline-block"
    >
        {{label}}
    </div>
</template>

<script>
export default {
    name: "LineNumber",
    props: {
        label: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>
    .line-number {
        width: 30px;
        height: 30px;
        vertical-align: center;
        text-align: center;
        border: 3px solid #cccccc;
        color: #cccccc
    }
</style>
