<template>
    <v-row class="pt-1 pb-0 pl-2 pr-2">
        <v-col
            v-if="!hideLineNumber"
            v-bind="lineNumberProps"
            class="pa-1 text-center"
        >
            <line-number v-if="lineNumber" :label="lineNumber" />
        </v-col>
        <v-col class="pa-1" v-if="title">
            <div class="line-item--label">
                <v-icon v-if="icon" small color="yellow darken-1">{{ icon }} </v-icon>
                {{ title }}
                <v-tooltip top v-if="tooltip">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                            mdi-help-circle
                        </v-icon>
                    </template>
                    <span style="white-space: pre">
                        {{ tooltip }}
                    </span>
                </v-tooltip>
            </div>
        </v-col>
        <v-col class="pa-1">
            <slot></slot>
        </v-col>
    </v-row>
</template>

<script>
import LineNumber from "components/LineNumber";
export default {
    name: "FieldRow",
    components: {
        LineNumber,
    },
    props: {
        lineNumber: {
            type: Number,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: false,
        },
        hideLineNumber: {
            type: Boolean,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            lineNumberProps: {
                md: "1",
                sm: "2",
                lg: "1",
            },
        };
    },
};
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
.v-input--selection-controls {
    margin-top: 0 !important;
}
</style>
